<div class="page-wrapper outdated-browser-container" *ngIf="!isModernBrowser; else appWrapper">
    <sfx-logo path="swissfex.svg#swissfex"></sfx-logo>
    <div>{{ 'global.browser_not_supported' | translate }}</div>
</div>
<ng-template #appWrapper>
    <div class="page-wrapper" *ngIf="isAppLoading; else outlet">
        <sfx-loader></sfx-loader>
    </div>
    <ng-template #outlet>
        <router-outlet></router-outlet>
    </ng-template>
</ng-template>

<div *ngIf="areDiagnosticsEnabled" style="position: absolute; top: 0; z-index: 1000; background-color: rgba(255, 255, 255, 0.8); color: #000;">
    <sfx-list [items]="diagnosticLogs | async | slice:0: 10" bullet="-">
        <ng-template #listItem let-item>{{ item.date }}: {{ item.key }} - {{ item.value }}</ng-template>
    </sfx-list>

    <sfx-button type="main" (clicked)="disabledDiagnostics()" label="Close"></sfx-button>
</div>